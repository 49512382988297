












import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapGetters('collections', [
      'collections'
    ]),
  },
  data() {
    return {
      selectedCollection: '',
    };
  },
  async created() {
    await this.loadCollections();
  },
  methods: {
    ...mapActions('collections', [
      'loadCollections',
    ]),

    select(collection) {
      this.selectedCollection = collection.slug;
      this.$emit('selectionChanged', collection.slug);
    },

    selectButtonClasses(collection) {
      let classes = 'list-group-item list-group-item-action d-flex justify-content-between align-items-center';

      if (this.selectedCollection === collection.slug) {
        classes = `${classes} active`;
      }

      return classes;
    }
  },
});
