<template>
  <div class="container body">
    <div class="view-album">

      <loading-indicator />

      <template v-if="currentAlbum">
        <div class="d-flex justify-content-between album-header">
          <h1>
            {{ currentAlbum.name }}
          </h1>
          <div class="actions mt-2">
            <div class="album-tools" v-if="!hasSelectedPhotos">
              <b-button variant="outline-secondary" class="mr-1" title="Set album cover" @click="openCoverSelector">
                <fa-icon icon="image" /> Set Cover
              </b-button>

              <b-button variant="outline-secondary" title="Edit album properties" @click="openPropertyEditor">
                <fa-icon icon="edit" /> Edit Properties
              </b-button>
            </div>

            <div class="selection-tools" v-if="hasSelectedPhotos">
              <b-button variant="outline-primary" title="Add photo(s) to a collection" @click="addSelectedToCollection">
                <fa-icon icon="images" /> Add to Collection
              </b-button>&nbsp;

              <b-button variant="outline-secondary" title="Clear selection" @click="clearSelection">
                Cancel
              </b-button>
            </div>
          </div>
        </div>
      </template>

      <photo-grid
        :photos="albumPhotos"
        allowSelection
      />

      <b-modal
        v-if="currentAlbum"
        v-model="showCoverSelector"
        size="lg"
        title="Select album cover"
        hide-footer
        no-close-on-backdrop
      >
        <photo-selector
          :photos="albumPhotos"
          :selectedId="currentAlbum.coverPhotoId"
          @photoSelected="updateCoverPhoto"
        />
      </b-modal>

      <add-collection-photos />

      <b-sidebar
        v-if="currentAlbum"
        v-model="showPropertyEditor"
        right
        shadow
        title="Album Properties"
      >
        <div class="px-3 py-2">
          <property-editor />
        </div>
      </b-sidebar>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields';
import AddCollectionPhotos from '@/components/collections/AddCollectionPhotos.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import PhotoGrid from '@/components/photos/PhotoGrid.vue';
import PhotoSelector from '../../components/photos/PhotoSelector.vue';
import PropertyEditor from '@/components/albums/PropertyEditor.vue';

export default {
  props: {
    slug: {
      type: String,
      required: true,
    }
  },
  components: {
    AddCollectionPhotos,
    LoadingIndicator,
    PhotoGrid,
    PhotoSelector,
    PropertyEditor,
  },
  data() {
    return {
      selectedPhotoIds: [],
    };
  },
  computed: {
    ...mapGetters('albums', [ 'currentAlbum' ]),
    ...mapFields('albums', [ 'showPropertyEditor', 'showCoverSelector' ]),
    ...mapGetters('photos', [
      'albumPhotos',
      'hasSelectedPhotos',
    ]),
  },
  watch: {
    slug: 'refreshAlbum'
  },
  async created() {
    await this.refreshAlbum();
  },
  methods: {
    ...mapActions('albums', [
      'loadAlbum',
      'setCoverPhoto',
      'openPropertyEditor',
      'openCoverSelector',
    ]),
    ...mapActions('photos', [
      'loadPhotosForAlbum',
      'clearSelection',
    ]),

    ...mapActions('collections', [
      'addToCollection',
    ]),

    async refreshAlbum() {
      await this.loadAlbum(this.slug);
      await this.loadPhotosForAlbum(this.slug);
    },

    async updateCoverPhoto(photoId) {
      try {
        await this.setCoverPhoto({ photoId: photoId });
      } catch(err) {
        console.error('error setting cover photo', err);
      }
    },

    addSelectedToCollection() {
      this.addToCollection(this.selectedPhotoIds);
    },
  },
  metaInfo() {
    return {
      title: this.currentAlbum ? this.currentAlbum.name : '',
    }
  },
}
</script>
