





















import Vue from 'vue'
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default Vue.extend({
  computed: {
    ...mapFields('albums', [
      'currentAlbum.name'
    ]),
  },

  methods: {
    ...mapActions('albums', [
      'updateAlbum'
    ])
  }
})
