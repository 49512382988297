















import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CollectionSelector from './CollectionSelector.vue';

export default Vue.extend({
  components: {
    CollectionSelector,
  },
  data() {
    return {
      selectedCollection: '',
    };
  },
  computed: {
    ...mapFields('collections', [
      'showAddToCollection'
    ]),
    ...mapGetters('photos', [
      'selectedPhotoIds',
    ]),
    title() {
      return `Add ${this.selectedPhotoIds.length} ${this.selectedPhotoIds.length > 1 ? 'photos' : 'photo' } to collection`
    },
  },
  methods: {
    ...mapActions('collections', [
      'addToCollectionSubmit',
    ]),

    selectedCollectionChanged(val) {
      this.selectedCollection = val;
    },

    async save(bvModalEvent) {
      bvModalEvent.preventDefault(); // Store will take care of hiding via state

      await this.addToCollectionSubmit(this.selectedCollection);

      this.$emit('photosAdded');
    },
  },
});
